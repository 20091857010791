import { GetServerDataReturn, navigate } from 'gatsby';
import React, { useEffect } from 'react';

import SafeDrivingTruckPage from '../components/SafeDrivingTruckPage';
import { GLOBAL_QUERY } from '../constants';
import { TPageProp } from '../types';
import { IGlobalServerRuntimeData } from '../types/strapi/global';
import { IPost, IPostTag } from '../types/strapi/posts';
import { isRu } from '../utils/locale';

import { getPosts } from './news/[code]';


interface IServerRuntimeData extends IGlobalServerRuntimeData {
    posts: Array<IPost> | null,
    selfDrivingTruckTag: {
        tags: Array<IPostTag>
    }
}

type TProp = TPageProp<IServerRuntimeData>;

const SelfDrivingTruck: React.FC<TProp> = ({ serverData }) => {
    const { navPanel, global, respondForm, footer, posts } = serverData;

    const isHiddenPage = true;

    useEffect(() => {
        if(isHiddenPage) {
            navigate('/');
        }
    }, []);

    if(isHiddenPage) {
        return null;
    }

    return (
        <SafeDrivingTruckPage
            data={{
                navPanel,
                global,
                respondForm,
                footer
            }}
            posts={posts}
        />
    );
};

export default SelfDrivingTruck;

const query = `
  query Global ($locale: String) {
    ${GLOBAL_QUERY}
    selfDrivingTruckTag(locale: $locale) {
      tags {
        id
        value
        text
      }
    }
  }
`;

export async function getServerData(): Promise<GetServerDataReturn<IServerRuntimeData>> {
    try {
        const variables = JSON.stringify({
            locale: process.env.GATSBY_LOCALE_CODE
        });
        const response = await fetch(
            `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
        );

        const data: { data: IServerRuntimeData } = await response.json();

        return {
            props: {
                selfDrivingTruckTag: data.data.selfDrivingTruckTag,
                posts              : isRu ? await getPosts(-1, data.data.selfDrivingTruckTag.tags.map((tag) => tag.value)) : null,
                navPanel           : data.data.navPanel,
                global             : data.data.global,
                respondForm        : data.data.respondForm,
                footer             : data.data.footer
            }
        };
    } catch(error) {
        return {
            status: 500
        };
    }
}
